import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { TextListItem } from '@naf/text-list';
import { useHistory } from 'react-router';
import { FrontPageType } from '../../../../../../types/frontPageType';
import BlockContent from '../../../../components/block-content/BlockContent';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import MemoizedImageOrVideo from '../../MemoizedImageOrVideo';
import * as S from './Hero.styles';

interface Props {
  hero?: FrontPageType['hero'];
}

export const Hero = ({ hero }: Props) => {
  const history = useHistory();
  const primaryCtaUrl = useDocumentUrlWithNode(hero?.ctaButton?.data);
  const secondaryCtaUrl = useDocumentUrlWithNode(hero?.secondaryCtaButton?.data);

  return (
    (hero && (
      <S.HeroContainer>
        <MemoizedImageOrVideo
          imageId={hero.image?.publicId}
          videoId={hero.video?.publicId}
          altText={hero.image?.altText}
        />
        <S.ContentContainer>
          <S.HeroContent>
            <S.Heading variant={TextVariant.Display}>{hero.header}</S.Heading>
            <BlockContent value={hero.body} />
            {hero.bulletList ? (
              <S.StyledTextList variant="check">
                {hero.bulletList?.map((item) => (
                  <TextListItem>
                    <Text tag="span" variant={TextVariant.Header3}>
                      {item}
                    </Text>
                  </TextListItem>
                ))}
              </S.StyledTextList>
            ) : null}
            <S.ButtonWrapper>
              {hero.ctaButton ? (
                <S.StyledButton
                  text={hero.ctaButton.buttonText}
                  size="large"
                  variant="signature"
                  onClickFn={() => {
                    if (primaryCtaUrl) {
                      history.push(primaryCtaUrl);
                    }
                  }}
                />
              ) : null}
              {hero.secondaryCtaButton ? (
                <S.StyledButton
                  text={hero.secondaryCtaButton.buttonText}
                  size="large"
                  variant="outline"
                  onClickFn={() => {
                    if (secondaryCtaUrl) {
                      history.push(secondaryCtaUrl);
                    }
                  }}
                />
              ) : null}
            </S.ButtonWrapper>
          </S.HeroContent>
        </S.ContentContainer>
      </S.HeroContainer>
    )) ||
    null
  );
};
