import { breakpoints, spacing } from '@naf/theme';
import React from 'react';
import styled from 'styled-components';
import { AdvancedVideo, placeholder, responsive } from '@cloudinary/react';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { MAX_WIDTH } from '../../components/layout/Layout';
import { useCloudinary } from '../../hooks/useCloudinary';
import { useDevicePixelRatio } from '../../hooks/useDevicePixelRatio';
import { usePrefersReducedMotion } from '../../hooks/usePreferReducedMotion';
import ResponsiveImage from '../../components/image/ResponsiveImage';

type Props = {
  imageId?: string;
  videoId?: string;
  altText?: string;
};

const MemoizedImageOrVideo = React.memo(({ imageId, videoId, altText }: Props) => {
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();
  const prefersReducedMotion = usePrefersReducedMotion();

  const cldVideo = videoId && cld.video(videoId).delivery(dpr(devicePixelRatio)).quality('auto:best').format('auto');

  return (
    <ImageContainer>
      <StyledResponsiveImage imageId={imageId} altText={altText} />
      {!imageId && cldVideo && (
        <StyledVideo
          cldVid={cldVideo}
          plugins={[responsive({ steps: 50 }), placeholder({ mode: 'blur' })]}
          controls={false}
          autoPlay={!prefersReducedMotion}
          muted
          playsInline
          loop
        />
      )}
    </ImageContainer>
  );
});

export const INNER_MAX_WIDTH = `calc(${MAX_WIDTH} - 2 * ${spacing.space48})`;

const StyledResponsiveImage = styled(ResponsiveImage)`
  display: block;
  object-fit: cover;
  height: 320px;
  width: 100%;

  @media screen and (min-width: ${breakpoints.m}) {
    height: 603px;
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.l}) {
    width: calc(${INNER_MAX_WIDTH} - 125px);
    max-height: 603px;
  }
`;

const StyledVideo = styled(AdvancedVideo)`
  display: block;
  object-fit: cover;
  height: 320px;
  width: 100%;

  @media screen and (min-width: ${breakpoints.m}) {
    height: 603px;
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.l}) {
    width: calc(${INNER_MAX_WIDTH} - 125px);
    max-height: 603px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;

  @media screen and (min-width: ${breakpoints.l}) {
    max-width: ${INNER_MAX_WIDTH};
    margin: 0 auto;
    transform: translateX(163px);

    ${StyledResponsiveImage}, ${StyledVideo} {
      display: block;
      margin-left: auto;
    }
  }
`;

export default MemoizedImageOrVideo;
