import styled from 'styled-components';
import { Text } from '@naf/text';
import { TextList } from '@naf/text-list';
import { breakpoints, fontStyle, mobile, spacing } from '@naf/theme';
import { INNER_MAX_WIDTH } from '../../MemoizedImageOrVideo';
import CtaButton from '../../../../components/ctaButton/CtaButton';

export const HeroContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: ${spacing.space160};

  @media (max-width: ${breakpoints.l}) {
    padding-top: ${spacing.space32};
  }

  @media (max-width: ${breakpoints.m}) {
    padding-top: ${spacing.space80};
  }
`;

export const HeroContent = styled.div`
  background: white;
  padding: ${spacing.space48} ${spacing.space64} ${spacing.space48} 0;
  max-width: 570px;

  p {
    margin-top: 0;
    margin-bottom: ${spacing.space32};
    ${fontStyle.headers.header3};
  }

  @media (max-width: ${breakpoints.xl}) {
    padding: ${spacing.space48} ${spacing.space64} ${spacing.space48} ${spacing.space48};
  }

  @media (max-width: ${breakpoints.l}) {
    padding: ${spacing.space24} ${spacing.space32};

    p {
      margin-bottom: ${spacing.space32};
    }
  }

  @media (max-width: ${breakpoints.m}) {
    padding: ${spacing.space12} ${spacing.space24};

    p {
      margin-top: ${spacing.space16};
      ${mobile.headers.header3};
    }
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
  transform: translateY(-${spacing.space48});
  margin: 0 ${spacing.space16};

  @media screen and (min-width: ${breakpoints.m}) {
    margin: 0 ${spacing.space64};
    transform: translateY(-${spacing.space80});
  }

  @media screen and (min-width: ${breakpoints.l}) {
    width: 100%;
    order: -1;
    box-sizing: border-box;
    transform: none;
    max-width: ${INNER_MAX_WIDTH};
    margin: 0 auto;
    position: relative;

    ${HeroContent} {
      position: absolute;
      left: 0;
      top: ${spacing.space64};
    }
  }
`;

export const Heading = styled(Text)`
  margin: 0;

  @media (min-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space32};
  }
`;

export const StyledButton = styled(CtaButton)`
  @media (min-width: ${breakpoints.m}) {
    padding: 19px 43px;
  }

  @media (min-width: ${breakpoints.l}) {
    padding: 19px ${spacing.space64};
  }
`;

export const StyledTextList = styled(TextList)`
  margin-bottom: ${spacing.space32};

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space16};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.space32};

  @media (max-width: ${breakpoints.s}) {
    flex-direction: column;
    align-items: center;
    gap: ${spacing.space16};

    button {
      width: 100%;
    }
  }
`;
